<template>
  <div class="flex flex-wrap w-full min-h-screen">
    <div class="grid grid-cols-2 w-full">
      <div class="flex flex-col items-center justify-center gap-2 w-full">
        <div class="flex justify-center" style="width: 25%">
          <img alt="logo" src="@/assets/images/sme_logo.png" />
        </div>
        <div class="flex flex-col gap-2" style="width: 60%">
          <h3 class="text-2xl text-center mt-4 mb-4" v-if="!requested">Can't log in?</h3>
          <span class="text-center" v-if="!requested">We'll send a recovery link to</span>
          <div class="field" v-if="!requested">
            <InputText class="w-full" type="text" name="email" v-model="email" v-validate="'required|email'" placeholder="Email address" />
            <span class="block text-red-500 italic text-left">{{ errors.first('email') }}</span>
          </div>
          <span class="text-center" v-if="requested">
            Check your inbox! We've sent a password recovery link to your email address. Follow the instruction in your email to reset the password. If you dont see our email,
            please check your spam or junk folder
          </span>
          <Button :label="resetButtonText" class="w-full p-button" @click.prevent="requestAccountRecovery" />
          <Divider />
          <Button label="Return to Log In" class="w-full p-button p-button-link" @click.prevent="$router.push({ name: 'login_page' })" />
        </div>
      </div>
      <div class="flex flex-col items-center justify-center border-l border-black">
        <img alt="forgot_password" src="@/assets/images/forgot-password.jpg" style="width: 80%" />
      </div>
    </div>
  </div>
</template>

<script>
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Divider from 'primevue/divider';
import auth_service from '@/services/auth_service';

export default {
  components: {
    Button,
    InputText,
    Divider,
  },
  data() {
    return {
      email: '',
      requested: false,
    };
  },
  computed: {
    resetButtonText() {
      return this.requested ? 'Resend Recovery Link' : 'Send Recovery Link';
    },
  },
  methods: {
    requestAccountRecovery() {
      auth_service
        .requestAccountRecovery(this.email)
        .then(() => {
          this.$msg.success({
            title: 'Account Recovery',
            message: 'We have sent you a link to reset your password<br/> please check your email',
            supportHTML: true,
          });
          this.requested = true;
        })
        .catch(({ response }) => this.$msg.error({ title: 'Account Recovery', message: response.data.error }));
    },
  },
};
</script>
